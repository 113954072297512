<template>
  <div
      v-loading="loading"
      element-loading-text="数据加载中……"
      element-loading-spinner="el-icon-loading">
    <el-row>
      <el-col :span="24">
        <div class="default-table tableBox">
          <el-table
              :data="tableData"
              style="width: 100%"
              class="default-table"
              :row-class-name="rowClassName"
              :highlight-current-row="true"
              @row-click="selectRow"
              :height="`100%`"
              border>
            <el-table-column
                prop="time_day"
                label="日期"
                min-width="100" align="center">
            </el-table-column>
            <!--            <el-table-column-->
            <!--                prop="group_name"-->
            <!--                label="部门-组"-->
            <!--                min-width="120" align="center">-->
            <!--            </el-table-column>-->
            <el-table-column
                prop="nickname"
                label="红人昵称"
                min-width="140" header-align="center" align="center">
            </el-table-column>
            <template v-for="(column,index) in columns">
              <el-table-column :prop="`${column.value}`" :key="index" :label="`${column.title}`"
                  :min-width="`${column.width}`" align="right" header-align="center">
                <editable-cell v-if="row.editable" :show-input="row.editable" slot-scope="{row}"
                    v-model="row[column.value]"
                    @change="selectInput(row,column.value)" :is-input-number="true">
                          <span slot="content">
                            <span>{{ row[column.value] }}</span>
                            <i class="el-icon-edit float-right" v-if="showEditIcon"></i>
                          </span>
                </editable-cell>
              </el-table-column>
            </template>
          </el-table>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import EditableCell from "@/components/EditableCell";
import { numberFormat } from "@/utils/utils";

export default {
  name: "ShopDataRecord",
  components: {
    EditableCell
  },
  props: {
    readOnly: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    searchCondition: {
      type: Object,
      default: () => {
        return {
          artist_id: null,
          nickname: '',
          income_code: 'shop_tb',
          platform_code: 'self_ec',
          platform_name: '自营电商',
          ym: null,
        }
      },
    },
    columnsMap: {
      type: Object,
      required: true
    }
  },
  watch: {
    searchCondition: {
      deep: true,
      handler( val ) {
        // console.log('val',val)
        this.initData(val)
      }
    }
  },
  data() {
    return {
      tblHeight: 500,
      showEditIcon: true,//是否显示编辑按钮
      loading: false,
      tableData: [],
      loadingStatus: false,
      offset: 0,
      activeNames: [],
      columns: [
        {value: 'num_visitor', title: '访客数', width: 120},
        {value: 'num_buyer', title: '买家数', width: 120},
        {value: 'rate_payment', title: '支付转化率(%)', width: 120},
        {value: 'amount_payment', title: '支付金额', width: 100},
        {value: 'c_unit_price', title: '客单价', width: 100},
        {value: 'amount_refund', title: '申请退款金额', width: 100},
        {value: 'd_score', title: '描述评分（D）', width: 100},
        {value: 's_score', title: '服务评分（S）', width: 100},
        {value: 'r_score', title: '物流评分（R）', width: 100},
      ],
      fields: [],
      currentRow: {},//当前选中的行
      currentColumn: {},//当前选中的列
    }
  },
  created() {
    const tblHeight = window.document.body.clientHeight

    // console.log('tbl-h', tblHeight)
    this.tblHeight = tblHeight - 200
  },
  mounted() {
    // 监听 ctrl+v键盘事件
    document.addEventListener('paste', this.getPasteText, false)
  },
  beforeDestroy() {
    document.removeEventListener('paste', this.getPasteText, false)
  },
  methods: {
    //列的字段集合（用于复制粘贴）
    initColFields() {
      let income_code = this.searchCondition.income_code
      this.columns = this.columnsMap[income_code] ? this.columnsMap[income_code].columns : [];
      if (this.columns) {
        this.fields = this.columns.map(( item ) => {
          return item.value
        })
      }
    },
    moneyFormat( value ) { // 金额 格式化
      return numberFormat(value, 2, '.', ',', 'round')
    },
    numFormat( value ) { // 数值 格式化
      return numberFormat(value, 0, '.', ',', 'round')
    },
    async initData( searchCondition ) {
      this.initColFields()//根据店铺类型更新显示列

      this.tableData = []

      if (searchCondition.artist_id && searchCondition.income_code && searchCondition.ym) {
        this.loading = true
        //已录入的数据
        let {list} = await this.$api.getShopInfoMonthData(searchCondition)
        this.$nextTick(() => {
          this.tableData = list
        })

        this.loading = false
      }

    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.platform_code) {
        condition.platform_code = this.searchCondition.platform_code
      }

      if (this.searchCondition.income_code) {
        condition.income_code = this.searchCondition.income_code
      }

      if (this.searchCondition.artist_id) {
        condition.artist_id = this.searchCondition.artist_id
      }

      return condition
    },
    //保存单元格数据
    async saveCell( row, field ) {
      await this.addShopInfoData(row, field)

      // let data = {}
      // if (row.id) {
      //   data['id'] = row.id;
      //   data['field'] = field;
      //   data['val'] = row[field];
      //
      //   let id = await this.$api.updateShopInfo(data)
      //   if (!id) {
      //     this.$notify.error('修改失败！')
      //   }
      // } else {
      //   await this.addShopInfoData(row)
      // }


    },
    selectInput( row, field ) {
      if (row[field] && row[field].length > 0)
        this.saveCell(row, field)
      else {
        row[field] = 0
        this.saveCell(row, field)
      }
    },
    rowClassName( {row, rowIndex} ) {
      // 把每一行的索引放进row.id
      row.index = rowIndex + 1
      try {
        let lastDayTimestamp = this.getLastDays(1)
        let time_day = row.time_day
        if (time_day.indexOf('-') > -1) {
          let arr = time_day.split('-')
          let day = new Date(arr[0], arr[1] - 1, arr[2]);
          let timestamp = day.getTime();
          let diff = timestamp - lastDayTimestamp;
          if (diff > 0) {
            row.editable = false
          } else {
            row.editable = true
          }
        } else {
          row.editable = false
        }
      } catch (e) {
        row.editable = true
      }


    },
    getLastDays( days ) {
      //几天前的时间
      let day1 = new Date();
      day1.setTime(day1.getTime() - 24 * 60 * 60 * 1000 * days);
      return day1.getTime();
    },
    //获取选中单元格信息
    selectRow( row, column ) {
      this.currentRow = row
      this.currentColumn = column
      // if (column)
      //   console.log(`${ event.type } ${ column.property }`)

    },
    // 获取粘贴文本处理：若有多列数据，则询问确认粘贴开始列
    getPasteText( event ) {
      let clipboardData = (event.clipboardData || window.clipboardData)
      const text = clipboardData.getData('text')
      let arrText = text.trim().split(/ [(\r\n)\r\n] /).join().split('\t') // 以转行符切割文本字符串
      if (arrText.length > 1) {
        //当前列的字段
        let field = this.currentColumn.property
        let label = this.currentColumn.label || '未知'
        let startIndex = this.fields.indexOf(field)
        let fields = this.fields
        let pasteText = arrText.join("\t")
        let confirmMsg = `整行列粘贴：[${pasteText}]，是否确认?`
        //计算从哪列开始填充
        if (startIndex > -1) {
          //若选中的是可填充列，则从选中列开始往后填充，否则，填充整列
          let len = arrText.length
          fields = this.fields.slice(startIndex, len + startIndex)
          confirmMsg = `从列【${label}】开始粘贴：[${pasteText}]，是否确认?`
        }

        this.$confirm(confirmMsg, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.saveRow(fields, arrText)
          // clipboardData.setData('text', '')
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
      }
    },
    async saveRow( fields, arrText ) {
      let row = {}
      for (let i = 0; i < fields.length; i++) {
        let f = fields[i]
        row[f] = arrText[i].trim()
      }
      await this.addShopInfoData(row)//添加整行
      await this.$notify.success('粘贴成功')
      //更新数据
      await this.initData(this.searchCondition)
    },
    //添加或更新
    async addShopInfoData( row ) {
      let data = {
        nickname: this.currentRow.nickname,
        artist_id: this.currentRow.artist_id,
        time_day: this.currentRow.time_day,
        platform_code: this.currentRow.platform_code,
        income_code: this.currentRow.income_code,
        income_name: this.currentRow.income_name,
        group_id: this.currentRow.group_id,
        group_name: this.currentRow.group_name,
      }
      Object.assign(data, row)//当前行原始数据+填充数据
      await this.$api.addShopInfo(data)
    },
  }
}
</script>

<style scoped>
.tableBox {
  height: 80vh;
}
</style>
