import { render, staticRenderFns } from "./ShopDataEnter.vue?vue&type=template&id=5bc81311&scoped=true&"
import script from "./ShopDataEnter.vue?vue&type=script&lang=js&"
export * from "./ShopDataEnter.vue?vue&type=script&lang=js&"
import style1 from "./ShopDataEnter.vue?vue&type=style&index=1&id=5bc81311&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bc81311",
  null
  
)

export default component.exports