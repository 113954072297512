<template>
  <div>
    <!--    <router-view v-show="$route.matched.length==3"></router-view>-->
    <PageHeaderLayout>
      <div class="main-page-content">
        <el-row class="searchCondition">
          <el-col :span="24">
            <el-form size="mini" :inline="false">
              <el-form-item label="">
                <el-date-picker
                    v-model="searchCondition.ym"
                    type="month"
                    placeholder="选择月" size="small" value-format="yyyy-MM" @change="showRecords">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="">
                <artist-search-custom :type="``" @handleSelect="selectArtist" :show-first-group="true"  style="width: 100%"/>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
        <!--        列表-->
        <el-row :gutter="1">
          <el-col :span="24">
            <el-tabs type="border-card" class="platforms-tab" :stretch="true" :tab-position="tabPosition"
                     style="height: auto;"
                     v-model="searchCondition.income_code"
                     @tab-click="handleClick" v-if="incomes.length>0">
              <el-tab-pane :label="income.name" :name="`${income.code}`" v-for="(income,indx) in incomes"
                           :key="`${income.code}_${indx}`" v-show="tabNames.indexOf(income.code)>-1">
                <span slot="label">
                  <div style="display: block;height: auto">
                      <el-avatar shape="square" :size="30" :src="income.logo_url" @error="true">
                                  <img src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"/>
                      </el-avatar>
                    <br/>
                     <el-tooltip class="item" effect="dark" :content="`${ income.name}`" placement="right">
                        <span class="platform_name">{{
                            income.name && income.name.length > 2 ? income.name.substring(0, 2) : income.name
                          }}</span>
                      </el-tooltip>
                  </div>
                </span>
              </el-tab-pane>
              <!--              店铺信息录入表格-->
              <shop-data-record :searchCondition="searchCondition" :columns-map="columnsMap"></shop-data-record>
            </el-tabs>
          </el-col>
        </el-row>

        <el-row v-if="userPermissions.indexOf('shop_data_log')>-1">
          <el-col :span="24">
            <el-divider/>
            <shop-info-log/>
          </el-col>
        </el-row>
      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PageHeaderLayout from "@/layouts/PageHeaderLayout";
import ShopDataRecord from "@/pages/shop_data/ShopDataRecord";
import ArtistSearchCustom from "@/components/artist/ArtistSearchCustom";
import ShopInfoLog from "@/pages/shop_data/ShopInfoLog";

export default {
  name: "ShopDataEnter",
  components: {
    PageHeaderLayout,
    ShopDataRecord,
    ShopInfoLog,
    ArtistSearchCustom
  },
  data() {
    return {
      groups: [],//当前页面展示的分组列表
      artists: [],//可选红人列表
      tabPosition: 'left',
      loadingStatus: false,
      // 表格列表数据
      dataList: [],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        page_size: 15
      },
      // 分页的offset,序号列使用
      offset: 0,
      columns: [],
      incomes: [{ code: 'shop_tb', name: '淘宝店' }, { code: 'shop_dy', name: '抖音店' }],
      tabNames: [],
      // 抽屉数据
      drawerData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        width_height: '480px',
        show_footer: false
      },
      searchCondition: {
        group_id: null,
        artist_id: null,
        nickname: null,
        ym: null,
        platform_code: 'self_ec',
        platform_name: '自营电商',
      },
      columnsMap: {}
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType'])
  },
  async created() {
    // 平台
    await this.getPageSettings()
    // 我的分组-》个人配置
  },
  mounted() {
    // this.initList()

  },
  watch: {
    "$route.matched": function (n) {
      if (n.length === 2) {
        // this.initList()
      }
    }
  },
  methods: {
    async shopInfoSettings() {
      let { map } = await this.$api.getShopInfoSettings();
      this.columnsMap = map
      let incomes = []
      Object.keys(this.columnsMap).forEach((key) => {
        let val = this.columnsMap[key]
        incomes.push({ code: val.code, name: val.name })
      })
      this.incomes = incomes

    },
    selectArtist(info) {
      this.searchCondition = { ...this.searchCondition, ...info }
      if (this.incomes.length > 0) {
        //默认第一个收入类型
        this.searchCondition.income_code = this.incomes[0].code
        this.searchCondition.income_name = this.incomes[0].name
      }
    },

    getCurrentMonth() {
      let currentDate = new Date()
      this.searchCondition.ym = currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1)
    },

    async changeArtist() {
      // 检索红人昵称
      let artist = this.artists.find((value) => value.id === this.searchCondition.artist_id)
      this.searchCondition.nickname = artist ? artist.nickname : ''

      if (this.incomes.length > 0) {
        this.searchCondition.income_code = this.incomes[0].code
        this.searchCondition.income_name = this.incomes[0].name
      }

      this.showRecords()
    },
    showRecords() {
      let income = this.incomes.find((value) => value.code === this.searchCondition.income_code)
      this.searchCondition.income_name = income.name
    },
    async getPageSettings() {
      //页面配置-依次加载 当前月份-》平台 我的分组-》红人
      await this.shopInfoSettings()
      await this.getCurrentMonth()
    },
    errorHandler() {
      return true
    },
    handleClick() {
      // console.log('tab', tab.name, event.type);
      this.showRecords()
    },
  },
}
</script>

<style scoped>

</style>
<style>

.platforms-tab .el-tabs__item {
  display: block;
  margin: 1px 1px;
  line-height: 25px;
  font-size: 1rem;
  /*word-wrap: break-word;*/
  writing-mode: horizontal-tb;
  height: 80px;
  padding-left: 10px;
  padding-top: 10px;
}

.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: #1890ff;
  background-color: #FFFFFF;
  border-right-color: #DCDFE6;
  border-left-color: #DCDFE6;
  font-weight: 600;
  font-size: 14px;
}

.el-select > .el-input {
  width: 200px !important;
}
</style>

