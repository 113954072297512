<template>
  <div>
    <el-card class="box-card">
      <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item title="操作日志" name="1">
          <div class="table-search">
            <el-form :inline="true" size="mini">
              <el-form-item label="数据ID：">
                <el-input v-model="searchCondition.info_id" placeholder="数据ID（精确匹配）" clearable/>
              </el-form-item>
              <el-form-item label="关键字：">
                <el-input v-model="searchCondition.keyword" placeholder="关键字（模糊匹配）" clearable/>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="switchPaging"><i class="el-icon-search"></i>搜索</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class="default-table">
            <ApeTable ref="apeTable" :data="logList" :columns="columns" :loading="loadingStatus"
                      :pagingData="pagingData"
                      @switchPaging="switchPaging" highlight-current-row border>
              <el-table-column
                  slot="first-column"
                  width="64"
                  label="序号">
                <template slot-scope="scope">
                  <span>{{ offset + scope.$index + 1 }}</span>
                </template>
              </el-table-column>
            </ApeTable>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-card>
  </div>
</template>

<script>
import ApeTable from "@/components/ApeTable";

export default {
  name: "ShopInfoLog",
  components: {
    ApeTable
  },
  props: {
    searchCondition: {
      type: Object,
      default: () => {
        return {
          info_id: null,
          keyword: null
        }
      },
    }
  },
  data() {

    return {
      loadingStatus: false,
      activeNames: [],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      // 分页的offset,序号列使用
      offset: 0,
      logList: [],
      columns: [
        {
          title: '操作时间',
          value: 'created_at',
          width: 140,
          align: 'center'
        },
        {
          title: '日志内容',
          value: 'msg',
          width: 800
        },
        {
          title: '数据ID',
          value: 'info_id',
          width: 100,
          align: 'center'
        }
      ],
    }
  },
  methods: {
    handleChange() {
      if (this.activeNames.length > 0)
        this.getLogList()
    },
    switchPaging() {
      this.getLogList()
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}

      if (this.searchCondition.platform_code) {
        condition.platform_code = this.searchCondition.platform_code
      }

      if (this.searchCondition.artist_id) {
        condition.artist_id = this.searchCondition.artist_id
      }

      if (this.searchCondition.time_value) {
        condition.start_time = this.searchCondition.time_value[0]
        condition.end_time = this.searchCondition.time_value[1]
      }

      return condition
    },
    async getLogList() {
      this.loadingStatus = true
      this.logList = []
      let pagingInfo = this.$refs['apeTable'].getPagingInfo()
      let searchCondition = this.handleSearchCondition()
      // 合并
      Object.assign(searchCondition, pagingInfo)
      let { list, pages } = await this.$api.getShopInfoLog(searchCondition)
      this.$nextTick(() => {
        this.logList = list
      })

      this.pagingData.total = pages.total
      this.offset = pages.offset
      this.loadingStatus = false

    },

  }
}
</script>

<style scoped>

</style>
